<template>
  <v-dialog v-model="show" persistent :max-width="800">
   <v-card class="d-flex flex-column align-center pa-3">
    <v-card-title class="primary--text">
      <h3>Deseja liberar esta movimentação?</h3>
    </v-card-title>

    <v-card-subtitle class="pt-2 primary--text">
      <label class="text-subtitle-1">
        <strong>{{ releaseMovementData.movementType }}: </strong>
        <span>{{ releaseMovementData.beneficiaryName }}</span>
      </label>
    </v-card-subtitle>

    <v-card-text class="my-3">
      <v-row align="center">
        <v-col cols="auto">
          <v-icon color="blue">mdi-information-slab-circle-outline</v-icon>
        </v-col>
        <v-col>
          <span class="grey--text" style="font-size: 14px;">
            <p class="my-0">Ao confirmar a movimentação terá o status alterado para "Liberado" e poderá ser enviado para a operadora.</p>
            <p class="mb-0 mt-2">Se optar por não realizar agora, você poderá liberar futuramente pela listagem de movimentações, buscando pelo status "Aguardando liberação".</p>
          </span>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-col>
        <v-btn large outlined color="primary" @click="$emit('close')">
          <v-icon left>far fa-clock</v-icon>
          <span>NÃO, PREFIRO MAIS TARDE</span>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn large color="primary" @click="$emit('confirm')">
          <v-icon left>fa-thin fa-clipboard-check</v-icon>
          <span>SIM, LIBERAR A MOVIMENTAÇÃO AGORA</span>
        </v-btn>
      </v-col>
    </v-card-actions>
   </v-card>
  </v-dialog>
  </template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default ({
  name: 'ReleaseMovementModal',

  data: () => ({
    show: false,
    releaseMovementData: {
      beneficiaryName: '-',
      movementType: 'Tipo da movimentação',
    },
  }),

  created() {
    this.formatter = new Formatters();
  },

  methods: {
    open(data) {
      this.releaseMovementData.beneficiaryName = data.beneficiaryName ? this.formatter.formatToTitleCase(data.beneficiaryName) : '-';
      this.releaseMovementData.movementType = data.movementType;

      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
});
</script>
