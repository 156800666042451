/* eslint-disable */
import HttpService from '../HttpService';
export default class BeneficiaryService {
    constructor() {
      this._httpService = new HttpService('/contract/beneficiary');
    }

    async FindAllByCpf(cpf){
        return await this._httpService.get( `/findAllByCpf/${cpf}`);
    }
 };
