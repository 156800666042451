<template>
  <div>
    <v-dialog v-model="viewCriticizedDialog" max-width="1200">
      <v-card class="pa-0">
        <v-card-title class="headline pl-3 ml-3">
          <v-row>
            <v-col cols="12" align="end">
              <v-icon large color="#4D596A" @click="close()">fas fa-times</v-icon>
            </v-col>
            <v-col cols="12" style="color: #3b495b; font-weight: bold">Críticas</v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-data-table
            :headers="dialogHeaders"
            :items="criticismItems"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.holderName`]="{ item }">
              {{ item.holderName ? formatter.formatToTitleCase(item.holderName) : '-' }}
            </template>
            <template v-slot:[`item.beneficiaryName`]="{ item }">
              {{ item.beneficiaryName ? formatter.formatToTitleCase(item.beneficiaryName) : '-' }}
            </template>
            <template v-slot:[`item.rule`]="{ item }">
              {{ item.rule ? item.rule : '-' }}
            </template>
            <template v-slot:[`item.field`]="{ item }">
              {{ item.field ? translateField(item.field) : 'Sem mensagem' }}
            </template>
            <template v-slot:[`item.fieldValue`]="{ item }">
              {{ item.fieldValue ? checkFormatValue(item) : 'Sem mensagem' }}
            </template>

            <template v-slot:no-data>
              <span>Nenhum item foi encontrado.</span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-btn fixed bottom left large color="red" class="mr-3" @click="open()">
      <v-icon medium color="white" class="mr-4">fas fa-exclamation-triangle</v-icon>
      <span class="white--text text-subtitle-1 font-weight-bold">
        Ver histórico de críticas da movimentação
      </span>
    </v-btn>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default ({
  data: () => ({
    viewCriticizedDialog: false,
    dataSourceColumn: null,
    criticismItems: [],
    dialogHeaders: [
      {
        text: 'Nome do Titular',
        value: 'holderName',
        align: 'center',
      },
      {
        text: 'Nome do Beneficiário',
        value: 'beneficiaryName',
        align: 'center',
      },
      {
        text: 'Regra',
        value: 'rule',
        align: 'center',
      },
      {
        text: 'Mensagem',
        value: 'message',
        align: 'center',
      },
      {
        text: 'Campo Criticado',
        value: 'field',
        align: 'center',
      },
      {
        text: 'Valor Criticado',
        value: 'fieldValue',
        align: 'center',
      },
    ],
  }),

  mounted() {
    this.loadCriticisms();
  },

  methods: {
    open() {
      this.viewCriticizedDialog = true;
    },
    close() {
      this.viewCriticizedDialog = false;
    },
    loadCriticisms() {
      if (sessionStorage.getItem('criticismRules')) {
        this.criticismItems = JSON.parse(sessionStorage.getItem('criticismRules'));
        this.dataSourceColumn = JSON.parse(sessionStorage.getItem('dataSourceColumn'));
      }
    },
    translateField(field) {
      if (field && field !== null) {
        const comparationField = this.dataSourceColumn.find((element) => element.value === field);
        if (comparationField) {
          return comparationField.label[0].toUpperCase() + comparationField.label.substring(1);
        }
      }
      return field;
    },
    checkFormatValue(item) {
      if (item && item.field !== null && item.field.includes('date')) {
        return this.formatter.formatDateTimeZone(item.fieldValue);
      }
      return item.fieldValue;
    },
  },

  created() {
    this.formatter = new Formatters();
  },
});
</script>
