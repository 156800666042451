/* eslint-disable */
import HttpService from '../HttpService';
import Formatters from '@/shared/formatters/formatters.js'

export default class MaritalStatusService {
  constructor() {
    this._httpService = new HttpService('/contract/marital_status');
    this.formatter = new Formatters();
  }
  
  async FindAll(){
    let maritalStatus = await this._httpService.get( '');
    maritalStatus.data = maritalStatus.data.map((marital) => ({
      ...marital,
      description: this.formatter.formatToTitleCase(marital.description),
    }));
    return maritalStatus;
  }
 };
