export default {
  data: () => ({
    states: [
      { value: 'Acre', label: 'AC' },
      { value: 'Alagoas', label: 'AL' },
      { value: 'Amapá', label: 'AP' },
      { value: 'Amazonas', label: 'AM' },
      { value: 'State of Amazonas', label: 'AM' },
      { value: 'Bahia', label: 'BA' },
      { value: 'Ceará', label: 'CE' },
      { value: 'Distrito Federal', label: 'DF' },
      { value: 'Espírito Santo ', label: 'ES' },
      { value: 'Goiás', label: 'GO' },
      { value: 'Maranhão', label: 'MA' },
      { value: 'Mato Grosso', label: 'MT' },
      { value: 'Mato Grosso do Sul', label: 'MS' },
      { value: 'Minas Gerais', label: 'MG' },
      { value: 'State of Minas Gerais', label: 'MG' },
      { value: 'Pará', label: 'PA' },
      { value: 'Paraíba', label: 'PB' },
      { value: 'Paraná', label: 'PR' },
      { value: 'Pernambuco', label: 'PE' },
      { value: 'Piauí', label: 'PI' },
      { value: 'Rio de Janeiro', label: 'RJ' },
      { value: 'State of Rio de Janeiro', label: 'RJ' },
      { value: 'Rio Grande do Norte', label: 'RN' },
      { value: 'Rio Grande do Sul', label: 'RS' },
      { value: 'Rondônia', label: 'RO' },
      { value: 'Roraima', label: 'RR' },
      { value: 'Santa Catarina', label: 'SC' },
      { value: 'State of Catarina', label: 'SC' },
      { value: 'São Paulo', label: 'SP' },
      { value: 'State of São Paulo', label: 'SP' },
      { value: 'Sergipe', label: 'SE' },
      { value: 'Tocantins', label: 'TO' },
    ],
    logTypes: [
      { value: 'AEROPORTO', label: 'Aeroporto' },
      { value: 'ALAMEDA', label: 'Alameda' },
      { value: 'ÁREA', label: 'Área' },
      { value: 'AVENIDA', label: 'Avenida' },
      { value: 'CAMPO', label: 'Campo' },
      { value: 'CHÁCARA', label: 'Chácara' },
      { value: 'COLÔNIA', label: 'Colônia' },
      { value: 'CONDOMÍNIO', label: 'Condomínio' },
      { value: 'CONJUNTO', label: 'Conjunto' },
      { value: 'DISTRITO', label: 'Distrito' },
      { value: 'ESPLANADA', label: 'Esplanada' },
      { value: 'ESTAÇÃO', label: 'Estação' },
      { value: 'ESTRADA', label: 'Estrada' },
      { value: 'FAVELA', label: 'Favela' },
      { value: 'FAZENDA', label: 'Fazenda' },
      { value: 'FEIRA', label: 'Feira' },
      { value: 'JARDIM', label: 'Jardim' },
      { value: 'LADEIRA', label: 'Ladeira' },
      { value: 'LAGO', label: 'Lago' },
      { value: 'LAGOA', label: 'Lagoa' },
      { value: 'LARGO', label: 'Largo' },
      { value: 'LOTEAMENTO', label: 'Loteamento' },
      { value: 'MORRO', label: 'Morro' },
      { value: 'NÚCLEO', label: 'Núcleo' },
      { value: 'PARQUE', label: 'Parque' },
      { value: 'PASSARELA', label: 'Passarela' },
      { value: 'PÁTIO', label: 'Pátio' },
      { value: 'PRAÇA', label: 'Praça' },
      { value: 'QUADRA', label: 'Quadra' },
      { value: 'RECANTO', label: 'Recanto' },
      { value: 'RESIDENCIAL', label: 'Residencial' },
      { value: 'RODOVIA', label: 'Rodovia' },
      { value: 'RUA', label: 'Rua' },
      { value: 'SETOR', label: 'Setor' },
      { value: 'SÍTIO', label: 'Sítio' },
      { value: 'TRAVESSA', label: 'Travessa' },
      { value: 'TRECHO', label: 'Trecho' },
      { value: 'TREVO', label: 'Trevo' },
      { value: 'VALE', label: 'Vale' },
      { value: 'VEREDA', label: 'Vereda' },
      { value: 'VIA', label: 'Via' },
      { value: 'VIADUTO', label: 'Viaduto' },
      { value: 'VIELA', label: 'Viela' },
      { value: 'VILA', label: 'Vila' },
    ],
    country: [
      { value: 'BRASIL', text: 'Brasil' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    nationalitys: [
      { value: 'BRASILEIRA', text: 'Brasileira' },
      { value: 'OUTROS', text: 'Outros' },
    ],
    identityDocumentNatureTypes: [
      'RG',
      'Passaporte',
      'RNE',
    ],
    managementStatus: [
      { value: '', text: 'Todos' },
      { value: 'AWAITING_PROCESSING', text: 'Aguardando Processamento' },
      { value: 'PROCESSING', text: 'Em Fila de Processamento' },
      { value: 'IMPLANTED', text: 'Configuração Ativa' },
      { value: 'NOT_CONFIGURED', text: 'Configuração inexistente' },
      { value: 'ERROR_PROCESSING', text: 'Falha no Processamento' },
      { value: 'WAIT_START', text: 'Em Processamento' },
      { value: 'PENDING_UPDATE', text: 'Pendente Sincronização' },
      { value: 'FIXED_CONFIGURATION', text: 'Configuração Fixa' },
    ],
  }),
};
