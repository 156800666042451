/* eslint-disable */
import HttpService from '../HttpService';
import Formatters from '@/shared/formatters/formatters.js'

export default class KinshipService {
  constructor() {
    this._httpService = new HttpService('/contract/kinship');
    this.formatter = new Formatters();
  }

  async FindAll(){
    let kinships = await this._httpService.get( '');
    kinships.data = kinships.data.map((kinship) => ({
      ...kinship,
      description: this.formatter.formatToTitleCase(kinship.description),
    }));
    return kinships;
  }
 };
