/* eslint-disable */
import HttpService from '../HttpService';
import Formatters from '@/shared/formatters/formatters.js'

export default class GenderService {
  constructor() {
    this._httpService = new HttpService('/contract/gender');
    this.formatter = new Formatters();
  }

  async FindAll(){
    let genders = await this._httpService.get( '');
    genders.data = genders.data.map((gender) => ({
      ...gender,
      description: this.formatter.formatToTitleCase(gender.description),
    }));
    return genders;
  }
 };
