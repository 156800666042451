<template>
  <v-dialog v-model="showModal" persistent max-width="1000">
    <v-card class="overflow-y-auto pa-5">
      <v-card-title class="primary--text text-h5">
        Movimentação com críticas de cadastro
      </v-card-title>

      <v-card-text class="mt-2">
        Sua movimentação apresenta uma ou mais críticas de cadatro.<br>
        Recomendamos que verifique e corrija agora, para que o registro possa prosseguir para liberação e envio à operadora,
        mas se preferir, você pode salvar a movimentação com as críticas e editar mais tarde.
      </v-card-text>

      <v-card-actions>
        <v-col>
          <v-btn class="elevation-0" large color="#edeff1" @click="sendRequestWithCriticism()">
            <v-icon left color="red">fas fa-long-arrow-alt-right</v-icon>
            <span class="red--text">Salvar com críticas e corrigir depois</span>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-btn large color="primary" @click="close">
            <v-icon left>fas fa-pencil-alt</v-icon>
            <span>Corrigir as críticas agora</span>
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default ({
  name: 'CriticismHandlingModal',
  data: () => ({ showModal: false }),

  methods: {
    open() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    },
    sendRequestWithCriticism() {
      this.$emit('confirm', true);
      this.close();
    },
  },
});
</script>
