/* eslint-disable */
import HttpService from '../HttpService';
export default class PhysicalPersonService {
    constructor() {
      this._httpService = new HttpService('/contract/physical_person');
    }

    async FindAllByFilters(params){
      return await this._httpService.get(`/findAllByFilters`, params);
    }

    async FindAllByFiltersQuery(query){
      return await this._httpService.get(`/findAllByFilters/${query}`);
    }

    async FindByCpf(cpf){
        return await this._httpService.get( `/findByCpf/${cpf}`);
    }

    async FindById(id){
      return await this._httpService.get( `/${id}`);
    }

    async FindByName(name){
        return await this._httpService.get( `/findByName/${name}`);
    }
 };
