<template>
  <div v-if="violations.length > 0">
    <v-row>
      <v-col cols="12" md="12">
        <v-alert
          text
          border="top"
          color="#A71A2A"
          class="mt-3"
        >
          <h3>
            Por favor verifique os campos!
          </h3>
          <v-col v-model="violations" v-for="(item, index) in violations" :key="index" cols="12">
            <span class="font-weight-bold" >• </span><span class="font-weight-bold" v-if="item.columnLabel">{{formatToTitleCase(item.columnLabel)}} </span> <span>{{ `- ${item.ruleName}` }}</span>
            <v-col cols="12" class="ma-1">
              <v-row class="ml-4" v-for="(item, index) in item.movementsDto" :key="index">
                <span>{{ getInfosFromMovementsDto(item)}}</span>
              </v-row>
              <v-row class="ml-4">
                <span>{{`Benefício: ${item.benefitType}`}}</span>
              </v-row>
            </v-col>
          </v-col>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';

export default {
  name: 'ViolationRulesAlert',
  props: { violations: [] },
  data: () => ({ formatter: new Formatters() }),
  watch: {
    violations(val) {
      this.violations = val;
    },
  },
  methods: {
    formatToTitleCase(str) {
      return this.formatter.formatToTitleCase(str);
    },
    getInfosFromMovementsDto(item) {
      const formatedName = this.formatToTitleCase(item.insured_name);
      return `Nome: ${formatedName} - Tipo: `.concat(item.beneficiary_type === 'HOLDER' ? 'Titular' : 'Dependente');
    },
  },
};
</script>
